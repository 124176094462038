import React, { useContext, useEffect, createRef } from "react";
import { Button, Section, Text } from "../components/Core";
import { navigate } from "gatsby"
import { Container, Col, Row } from "react-bootstrap";
import styled from "styled-components";

const SuccessCSAT = () => {
    
    useEffect(() => {
        
    }, [])

    return (
        <>
            <Section className="green-bg text-center">
                <Container className="position-relative">
                    <Row className="justify-content-center mt-50 mb-50">
                        <Col sm={12}>
                            <h3>Thank you<br />for your submitting the survey.</h3>
                            <Button
                                onClick={() => navigate('/')}
                                className="mt-30 blue-bg"
                            >
                                Homepage
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Section>
        </>
    )
}

export default SuccessCSAT